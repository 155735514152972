<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <a-typography-title>
        ADS Privacy Policy
      </a-typography-title>
      <a-typography-title :heading="6">
        We take your privacy seriously and are committed to protecting your personal information. This Privacy Policy
        describes how we collect, use, and share your personal information when you use our services. Please read it
        carefully.
      </a-typography-title>
      <a-typography-title :heading="6">
        Updated: April 2023
      </a-typography-title>
      <a-typography-title :heading="4">
        Introduction
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        This ADS Privacy Policy (this "Policy") is designed to inform you about how we collect and treat personal
        information obtained through our website, including portals and applications linked to it (collectively,
        "Website"). Please note that this Policy does not apply to personal information practices of third-party websites
        or online services, including those accessed through links provided on our website.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        We reserve the right to modify this Policy at any time, and any updates will be effective immediately upon posting
        on our Website. If we make significant changes to this Policy, we may also notify you directly via email or
        through a prominent notice posted on our website. We encourage you to check this Policy periodically to stay
        informed of how we are protecting your Personal Information.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        Our commitment to protecting your Personal Information includes adhering to industry standards and best practices.
        We take appropriate technical, physical, and administrative measures to ensure that your Personal Information is
        safe from unauthorized access, alteration, or destruction. Our employees are also trained in data protection and
        privacy practices to ensure that they handle your Personal Information with the utmost care.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        We value your trust and confidence in our ability to protect your Personal Information. If you have any questions
        or concerns about this Policy or our data protection practices, please do not hesitate to contact us through the
        channels provided on our Website.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Personal Information
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        In this Policy, the term "Personal Information" refers to information that can identify an individual, such as
        their name, address, email address, phone number, payment information, and any information related to their
        previous use of ADS's services. This definition is not exhaustive and may include other information that can be
        used to identify an individual.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Information we collect
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        This Policy outlines how ADS obtains and uses your Personal Information, which refers to any information that can
        be used to identify you as an individual. ADS may collect your Personal Information through various means, such as
        when you use our Website or services for shipping, tracking shipments, or making inquiries.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        The Personal Information that ADS collects includes identifiers such as your name, address, email, and phone
        number, as well as device information like browsing history and geolocation data. This information is collected
        when you create an ADS account, use our services, access our portals, or respond to our promotions or surveys.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        When ADS picks up or delivers a shipment, they may also obtain location data from your device, such as your IP
        address or device identifiers. ADS may use "cookies", which are text files that uniquely identify your browser or
        store information, to collect and link data elements to personalize your experience and improve our services. ADS
        may also use third-party analytics tools to gather information about visitor traffic on their websites or apps.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        You can control the collection of certain information by adjusting your device or browser settings, but note that
        disabling cookies may limit your access to ADS's features. Additionally, this Policy only applies to Personal
        Information that ADS collects and does not cover information that is publicly available or actions taken by
        individuals that ADS does not manage.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Use of information
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        ADS utilizes your Personal Information for various purposes including, but not limited to:<br />
        1.facilitating the pickup, delivery, and tracking of your shipments <br />
        2.establishing and managing your account with ADS<br />
        3.managing the processing and collection of your payments<br />
        4.providing customer service and communicating with you regarding any inquiries, requests, comments, or
        questions you may have<br />
        5.reviewing employment and engagement opportunities<br />
        6.interacting with portal users in accordance with their respective user statusesbr <br />
        7.administering and communicating about special events, programs, surveys, contests, sweepstakes, and other
        promotional offers or opportunities, such as sending you newsletters.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        Moreover, we may also employ your Personal Information to: <br />
        1.Handle claims we may receive in connection with our services<br />
        2.Evaluate, operate, and enhance our business operations<br />
        3.Perform data analyses to derive useful insights<br />
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Sharing of information
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        ADS values your privacy and does not disclose or sell your Personal Information to non-affiliated third parties
        unless we have your explicit consent. However, we may share your Personal Information in the following
        circumstances:<br />
        1. With authorized independent contractors and vendors who assist us in providing services to you.<br />
        2. If ADS believes in good faith that disclosure is necessary to investigate, prevent, or take action regarding
        illegal activities, suspected fraud, potential threats to the physical safety of any person, violations of ADS's
        terms of use, or as otherwise required by applicable law.<br />
        3. In response to a request from a governmental agency, subpoena, court order, legal process, or to establish or
        exercise ADS's legal rights, or defend against legal claims that may impact ADS.<br />
        4. With professional advisors and auditors who provide services to ADS.<br />
        5. With our route optimization partners and other data aggregators who assist us in improving our services.<br />
        6. Rest assured that we take appropriate measures to ensure that our authorized contractors and vendors adhere to
        strict confidentiality and data protection requirements.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Retention of information
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        We value your feedback and may keep it to help us improve our services. However, we request that you refrain from
        sending us any confidential information, ideas, proposals or comments that you wish to keep confidential.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        We will not keep your Personal Information for longer than necessary or as required by applicable laws or
        policies. Once we no longer have a legitimate reason to process your Personal Information, we will either delete
        or anonymize it. In situations where we cannot delete your information (for instance, if it is stored in backup
        archives), we will store it securely and prevent any further processing until it can be deleted.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        If you wish to request the deletion of your Personal Information that you have provided to us, you can contact us
        at americandeliveryservice@gmail.com via email. We will process your request in accordance with applicable laws
        and our data retention policies.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        How we handle the photo on delivery
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        ADS has authorized its contracted delivery vendors to capture package photos during the delivery process. These
        photos will focus solely on the placement of the package and are not stored on the vendor's device. The purpose of
        capturing delivery photos is to provide customers with the reassurance that their package has been safely
        delivered.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        ADS may use these delivery photos for various purposes such as verifying the delivery location, auditing for
        quality purposes, or assisting in the tracking and location of missing packages.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        If a delivery photo is captured and meets quality standards, it will be made visible to shippers and consumers
        when tracking their package on the ADS website.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        International Users
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our website is designed to serve customers and consumers located in the United States, and our services are hosted
        and performed within the country. Therefore, we kindly request that individuals physically located within any of
        the European Union countries refrain from submitting any Personal Information to us. If you believe that you may
        have unintentionally shared Personal Information with us, please contact us using the information provided in the
        "How to Contact Us" link below, and we will promptly remove it.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        For those accessing our website from Asia or any other region outside of the United States where laws and
        regulations pertaining to personal data collection, use, and disclosure may differ from those within the United
        States, please be aware that by using our website or services, you are consenting to the transfer of your
        information to the United States. Our services and website are governed by U.S. law and these terms.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        Minors
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        Our online job application system is intended for use by individuals who are 18 years of age or older. Therefore,
        individuals who are minors under the age of 18 are not permitted to provide any information to, on, or through our
        Website. If we become aware that we have unintentionally collected or received personal information from a child
        under the age of 18, we will immediately delete that information from our records.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        If you believe that we may have collected or received any information from or about a child under the age of 18,
        please contact us at "How to Contact Us" link below so that we can take prompt action to address the matter.
      </a-typography-paragraph>
      <a-typography-title :heading="4">
        How to contact us
      </a-typography-title>
      <a-typography-paragraph type="secondary">
        If you have questions related to this privacy policy, please email us at
        americandeliveryservice@gmail.com, or you can write to us at:
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        American Delivery Service, Inc.
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        Attn: Privacy Coordinator
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        13 Jensen dr
      </a-typography-paragraph>
      <a-typography-paragraph type="secondary">
        Somerset, NJ 08873
      </a-typography-paragraph>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku7.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    background-clip: content-box;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;
}
</style>
